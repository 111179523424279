<template>
  <vs-sidebar class="items-no-padding" parent="#todo-app" :click-not-close="clickNotClose" :hidden-background="clickNotClose" v-model="active">
    <component :is="scrollbarTag" class="todo-scroll-area" :settings="{ maxScrollbarLength: 60, wheelSpeed: 0.30 }">
      <div class="todo__filters-container">
        <!-- proyek -->
        <div class="px-6">
          <h5>Filter</h5>
          <div class="vx-card w-full mt-4 shadow-none">
            <label class="ml-1 text-xs opacity-75">Pilih Proyek</label>
            <v-select :options="proyek" label="nama" :clearable="false" v-model="filter.proyek" @input="onChangeProyek"/>
          </div>
        </div>

        <!-- siteplan -->
        <div class="px-6 pb-4">
          <div class="vx-card w-full mt-2 shadow-none">
            <label class="ml-1 text-xs opacity-75">Pilih Site Plan</label>
            <v-select :options="filter.proyek.siteplan" label="nama" :clearable="false" v-model="filter.siteplan"/>
          </div>
        </div>

        <vs-divider></vs-divider>

        <div class="px-6 py-4">
          <h5>Status Unit</h5>
          <div class="todo__lables-list">
          <span class="todo__label flex items-center mt-5 cursor-pointer" v-for="(item, index) in statusIndicator" :key="index" @click="onClickStatus(item.status)">
            <vs-icon icon="home" size="2rem" :class="['text-' + item.color, 'mr-2']"/>
            <span class="text-lg" :class="{'text-primary font-bold': filter.status === item.status}">{{ item.status }}</span>
          </span>
          </div>
        </div>

      </div>
    </component>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import ProyekRepository from '@/repositories/master/proyek-repository'

export default {
  name: 'SiteplanFilter',
  components: {
    VuePerfectScrollbar,
    'v-select': vSelect
  },
  props: ['active', 'clickNotClose', 'statusIndicator'],
  created () {
    this.setSidebarWidth()
  },
  mounted () {
    this.getAllProyek()
  },
  data () {
    return {
      loading: false,
      proyek: [],
      filter: {
        proyek: {
          id: null,
          nama: null,
          siteplan: []
        },
        siteplan: {
          id: null,
          nama: null
        },
        status: null
      }
    }
  },
  watch: {
    filter: {
      deep: true,
      handler (val) {
        this.$emit('filterUpdated', val)
      }
    },

    windowWidth () {
      this.setSidebarWidth()
    }
  },
  methods: {
    getAllProyek () {
      this.loading = true
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAllWithSiteplan(params)
        .then(response => {
          this.proyek = response.data.data

          // select first for default
          if (this.proyek.length > 0) {
            this.filter.proyek = this.proyek[0]
            if (this.filter.proyek.siteplan.length > 0) {
              this.filter.siteplan = this.filter.proyek.siteplan[0]
            }
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    onChangeProyek () {
      if (this.filter.proyek.siteplan.length > 0) {
        this.filter.siteplan = this.filter.proyek.siteplan[0]
      } else {
        // reset to empty siteplan
        this.filter.siteplan = { id: null, nama: null }
      }
    },

    onClickStatus (status) {
      const currentStatus = this.filter.status
      if (currentStatus === status) {
        this.filter.status = null // all
      } else {
        this.filter.status = status
      }
    },

    setSidebarWidth () {
      if (this.windowWidth < 992) {
        this.active = this.clickNotClose = false
      } else {
        this.active = this.clickNotClose = true
      }
      this.$emit('update:active', this.active)
      this.$emit('update:clickNotClose', this.clickNotClose)
    },

    toggleSidebar (value = false) {
      if (!value && this.clickNotClose) return
      this.active = value
      this.$emit('update:active', value)
    }
  },
  computed: {
    scrollbarTag () {
      return this.$store.getters['theme/scrollbarTag']
    },
    windowWidth () {
      return this.$store.state.theme.windowWidth
    }
  }
}
</script>
