<template>
  <div id="todo-app" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
    <!--filter-->
    <SiteplanFilter ref="filterComponent"
                    :active.sync="sidebar.active"
                    :click-not-close.sync="sidebar.clickNotClose"
                    :status-indicator="statusIndicator"
                    @filterUpdated="onFilterUpdated"/>

    <!--box-->
    <div :class="{'sidebar-spacer': sidebar.clickNotClose}" class="no-scroll-content border border-r-0 border-b-0 border-t-0 border-solid d-theme-border-grey-light">
      <div class="flex d-theme-dark-bg items-center border border-l-0 border-r-0 border-t-0 border-solid d-theme-border-grey-light">
        <feather-icon class="md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer" icon="MenuIcon" @click.stop="$refs.filterComponent.toggleSidebar(true)"/>
        <div class="w-full flex">
          <feather-icon icon="SearchIcon" svgClasses="h-4 w-4" class="py-2 px-4"/>
          <div class="vx-card p-0 m-0">
            <v-select class="vs-input-no-border vs-input-no-shdow-focus w-full v-select-no-border"
                      :options="searchablePropertiUnit"
                      label="no_unit"
                      :clearable="true"
                      v-model="search"
                      @input="onSearchSelected"
                      placeholder="Cari Nomor Unit"/>
          </div>
        </div>
      </div>

      <!--main content-->
      <div class="todo-content-scroll-area overflow-hidden">
        <div class="w-full h-full" :class="{'animation-pulse': loading}">
          <LMap v-if="siteplan != null"
                ref="siteplanMap"
                :center="map.center"
                :zoom="map.zoom"
                :min-zoom="map.minZoom"
                :max-zoom="map.maxZoom"
                @ready="onMapReady"
                @contextmenu="onMapRightClicked">
              <LMarker v-for="item in propertiUnitMarkedOnly"
                       :key="item.id"
                       :lat-lng="{ lat: item.latitude, lng: item.longitude }"
                       @click="onMarkerClicked(item)">
                <LIcon :icon-anchor="[15, 15]">
                  <vs-icon icon="home" size="2rem" :class="'text-' + getColorOfStatus(item.status_unit)"/>
                </LIcon>
              </LMarker>
          </LMap>

          <div v-else class="flex flex-col items-center opacity-25 h-full justify-center">
            <feather-icon icon="MapIcon" class="m-4" svgClasses="w-16 h-16"></feather-icon>
            <h6 class="px-5 text-center">Tidak ada siteplan.</h6>
          </div>
        </div>
      </div>
    </div>

    <!--modals-->
    <SiteplanAddMarker :isActive.sync="modalAddAMarker.active"
                       :data="modalAddAMarker.data"
                       @success="getPropertiUnit"/>

    <SiteplanDetailUnit :isActive.sync="modalDetailUnit.active"
                       :id-properti-unit="modalDetailUnit.data.id_properti_unit"
                        @removedFromSiteplan="getPropertiUnit"/>
  </div>
</template>

<script>
import SiteplanFilter from '@/views/pages/proyek/siteplan/SiteplanFilter'
import L from 'leaflet'
import { LIcon, LMap, LMarker } from 'vue2-leaflet'
import PropertiUnitRepository from '@/repositories/master/properti-unit-repository'
import _ from 'lodash'
import vSelect from 'vue-select'

export default {
  name: 'Siteplan',
  components: {
    SiteplanAddMarker: () => import('@/views/pages/proyek/siteplan/SiteplanAddMarker'),
    SiteplanDetailUnit: () => import('@/views/pages/proyek/siteplan/SiteplanDetailUnit'),
    'v-select': vSelect,
    SiteplanFilter,
    LMap,
    LMarker,
    LIcon
  },
  data () {
    return {
      modalAddAMarker: {
        active: false,
        data: {}
      },
      modalDetailUnit: {
        active: false,
        data: {}
      },
      loading: false,
      search: null,
      map: {
        instance: null,
        zoom: 2,
        minZoom: -2,
        maxZoom: 6,
        center: [0, 0],
        crs: L.CRS.Simple,
        layer: [],
        image: {}
      },
      sidebar: {
        active: true,
        clickNotClose: true,
        filter: {}
      },
      propertiUnit: []
    }
  },
  computed: {
    statusIndicator () {
      return [
        {
          status: 'Open',
          color: 'green'
        },
        // {
        //   status: 'Reservasi',
        //   color: 'green'
        // },
        {
          status: 'Booking',
          color: 'yellow-darken'
        },
        {
          status: 'Kontrak',
          color: 'red'
        },
        {
          status: 'SP3K',
          color: 'blue'
        },
        {
          status: 'Akad',
          color: 'cyan'
        },
        {
          status: 'Serah Terima',
          color: 'orange'
        }
      ]
    },
    proyek () {
      const filter = this.sidebar.filter
      if (filter.proyek == null || filter.proyek.id == null) return null
      return filter.proyek
    },
    siteplan () {
      const filter = this.sidebar.filter
      if (filter.siteplan == null || filter.siteplan.id == null) return null
      return filter.siteplan
    },
    propertiUnitMarkedOnly () {
      const idSiteplan = this.siteplan.id
      const currentStatusFilter = this.sidebar.filter.status
      return _.filter(this.propertiUnit, (item) => {
        // filter by status
        if (currentStatusFilter != null) {
          return item.latitude != null && item.longitude != null && item.id_siteplan === idSiteplan && (item.status_unit.toLowerCase() === currentStatusFilter.toLowerCase())
        }

        return item.latitude != null && item.longitude != null && item.id_siteplan === idSiteplan
      })
    },
    searchablePropertiUnit () {
      return _.filter(this.propertiUnit, item => {
        return item.id_siteplan !== null && (item.id_siteplan === this.sidebar.filter.siteplan.id)
      })
    }
  },
  methods: {
    onMapReady () {
      this.map.instance = this.$refs.siteplanMap.mapObject

      const map = this.map.instance
      const maxZoom = map.getMaxZoom() - 3
      const imageUrl = this.map.image.url
      const imageWidth = this.map.image.width
      const imageHeight = this.map.image.height

      // calculate the edges of the image, in coordinate space
      const southWest = map.unproject([0, imageHeight], maxZoom)
      const northEast = map.unproject([imageWidth, 0], maxZoom)
      const bounds = new L.LatLngBounds(southWest, northEast)
      const imageOverlayLayer = L.imageOverlay(imageUrl, bounds)
      imageOverlayLayer.addTo(map)
      this.map.layer.push(imageOverlayLayer)

      // tell leaflet that the map is exactly as big as the image
      map.setMaxBounds(bounds)
    },

    onFilterUpdated (val) {
      this.sidebar.filter = val

      // update map image
      if (this.siteplan != null) {
        this.map.image = {
          url: this.siteplan.gambar_url,
          width: this.siteplan.gambar_width,
          height: this.siteplan.gambar_height
        }
        if (this.map.layer.length > 0) {
          this.map.instance.removeLayer(this.map.layer[0])
        }
        if (this.map.instance != null) {
          this.onMapReady()
          this.map.instance.setZoom(2)
        }
      }

      // clear search
      this.search = null

      // get properti unit
      if (this.proyek != null) {
        this.propertiUnit = []
        this.getPropertiUnit()
      }
    },

    getPropertiUnit () {
      this.loading = true
      const idProyek = this.proyek.id

      PropertiUnitRepository.getAllByProyek(idProyek)
        .then(response => {
          this.propertiUnit = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    onMapRightClicked (e) {
      this.modalAddAMarker.data = {
        coordinates: { latitude: e.latlng.lat, longitude: e.latlng.lng },
        propertiUnit: this.propertiUnit,
        siteplan: this.siteplan
      }
      this.modalAddAMarker.active = true
    },

    onMarkerClicked (item) {
      this.modalDetailUnit.data = {
        id_properti_unit: item.id
      }
      this.modalDetailUnit.active = true
    },

    onSearchSelected (item) {
      if (item != null) {
        this.zoomMapToCoordinates(item.latitude, item.longitude, 5)
      } else {
        this.zoomMapToCoordinates(0, 0, 2)
      }
    },

    zoomMapToCoordinates (latitude, longitude, zoomLevel) {
      this.map.instance.setView([latitude, longitude], zoomLevel)
    },

    getColorOfStatus (status) {
      const statusIndicator = _.find(this.statusIndicator, (item) => {
        return item.status.toLowerCase() === status.toLowerCase()
      })
      return statusIndicator != null ? statusIndicator.color : 'black'
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vuexy/apps/todo.scss";
</style>
